<template>
  <div class="auth-page">
    <div class="container page">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-xs-12 box-container">
          <div class="text-center">
            <img src="/static/img/brickex.png" style="max-width: 100%; margin-bottom: 20px;"/>
          </div>
          <div class="white-box">
            <h1 class="text-xs-center">Loging out...</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import store from '@/store'
import '../assets/scss/login-register.css'

export default {
  name: 'Logout',
  data () {
    return {
    }
  },
  mounted: function () {
    store.state.auth = null
    if (store.state.auth === null) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
